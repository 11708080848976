import styled from "styled-components";

const FooterWrapper = styled.footer`
  position: relative;
  background-color: #ffffff;
  overflow: hidden;

  .footer_container {
    position: relative;
    max-width: 1200px;
    padding-top: 80px;
    padding-bottom: 60px;
  }

  .footerCopyrights {
    margin-top: 10px;

    font-size: 12px;
    color: #878787;
  }

  .privacyPolicy {
    color: #878787;
  }

  .footerSlogan {
    margin-top: 20px;
    color: #878787;
    margin-bottom: 50px;
  }

  .footerText p,
  .footerText {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.67;
    color: #878787;
    a {
      color: #c36276;
      text-decoration: underline;
    }
  }
  .footerSocial {
    display: flex;
    margin-top: 30px;
    align-items: center;
    a + a {
      margin-left: 15px;
    }
  }
  .footerSocialIcon {
    width: 45px;
  }
`;

const List = styled.ul``;

const ListItem = styled.li`
  a {
    color: #878787;
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
    }
  }
`;

export { List, ListItem };

export default FooterWrapper;
